import ConfettiGenerator from 'confetti-js';

const confettiSettings = {
  target: 'confetti' ,
  clock: 5,
  rotate: true
};

const confetti = new ConfettiGenerator(confettiSettings);
confetti.render();
